body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}

/* To hide columns from gridColumnPanel in datagrid. */
.MuiFormControlLabel-root.Mui-disabled.MuiFormControlLabel-labelPlacementEnd
{
  display: none;
}

.MuiDataGrid-cell
{
  align-items: flex-start!important;
}
